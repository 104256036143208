<template>
  <v-progress-linear
    :height="8"
    :color="color"
    :value="Math.floor(100 * (currentTime / timeout))"
  />

</template>

<script>
export default {
  data () {
    return {
      currentTime: 0
    }
  },
  mounted () {
    this.syncPbar()
  },
  methods: {
    syncPbar () {
      // Create a timeout every 100 miliseconds
      setTimeout(() => {
        // Increment the time counter by 100
        this.currentTime += 100

        // If our current time is larger than the timeout
        if (this.timeout <= this.currentTime) {
          // Wait 500 miliseconds for the dom to catch up, then reset the snackbar
          setTimeout(() => {
            this.$emit('timer-done')
          }, 1000)
        } else {
          // Recursivly update the progress bar
          this.syncPbar()
        }
      }, 100)
    }
  },
  props: {
    timeout: {
      default: 5 * 1000
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>

<style>
</style>
